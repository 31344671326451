import React from "react";
import styles from "./index.module.scss";

interface HeadingSubheadingProps {
  subheading: string;
  mainHeading: string;
  additionalClassName?: string;
  handleClick?: () => void;
}

const HeadingSubHeading: React.FC<HeadingSubheadingProps> = ({
  subheading,
  mainHeading,
  additionalClassName,
  handleClick,
}) => {
  return (
    <div
      onClick={handleClick}
      className={`${styles.headingSubheadingContainer} ${additionalClassName}`}
    >
      <h5 className={styles.headingText}>{subheading}</h5>
      <h3 className={styles.subHeadingText}>{mainHeading}</h3>
    </div>
  );
};

export default HeadingSubHeading;
