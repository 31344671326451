import React from "react";
import HeadingSubHeading from "../HeadingSubHeading";
import styles from "./index.module.scss";
import ihgLogo from "../../assets/images/leonia-icong.svg";

export interface HeadingsData {
  subheading: string;
  mainHeading: string;
  type: string;
}
interface RoomAllocationKPIProps {
  headingsData: HeadingsData[];
  handleKpiClick?: (type: string) => void;
}
const RoomAllocationKPI: React.FC<RoomAllocationKPIProps> = ({
  headingsData,
  handleKpiClick,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.kpiContainer}>
        {headingsData.map((item, index) => (
          <HeadingSubHeading
            key={index}
            handleClick={() => handleKpiClick && handleKpiClick(item.type)}
            subheading={item.subheading}
            mainHeading={item.mainHeading}
            additionalClassName={styles.headingSubheadingContainer}
          />
        ))}
      </div>
      <div className={styles.logoIcon}>
        <img src={ihgLogo} alt="ihglogo" />
      </div>
    </div>
  );
};

export default RoomAllocationKPI;