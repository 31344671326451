
const images = {
    radio_button: require('../assets/images/radio-button.png'),
    car : require('../assets/images/car-profile.png'),
    air_plane: require('../assets/images/air-plane.png'),
    van : require('../assets/images/van.png'),
    back: require('../assets/images/back.png'),
    map_pin: require('../assets/images/map-pin.png'),
    phone: require('../assets/images/phone.png'),
    email: require('../assets/images/email.png'),
    details: require('../assets/images/details.png'),
    registerButton: require('../assets/images/Button.png'),
    complete_tick: require('../assets/images/complete-tick.png'),
    list_black: require('../assets/images/list-black.webp'),
    list_white: require('../assets/images/list-grey.webp'),


}
export default images;