export const programs = [
    {
      "program_name": "HDB 1",
      "timeline": "8 Days",
      "schedule": "29th Sept '24 - 6th Oct '24",
      "investment": "₹ 2,34,000"
    },
    {
      "program_name": "HDB 2",
      "timeline": "10 Days",
      "schedule": "10th Oct '24 - 19th Oct '24",
      "investment": "₹ 2,50,000"
    },
    {
      "program_name": "HDB 3",
      "timeline": "7 Days",
      "schedule": "20th Oct '24 - 26th Oct '24",
      "investment": "₹ 2,10,000"
    },
    {
      "program_name": "HDB 4",
      "timeline": "9 Days",
      "schedule": "27th Oct '24 - 4th Nov '24",
      "investment": "₹ 2,45,000"
    },
    {
      "program_name": "HDB 5",
      "timeline": "5 Days",
      "schedule": "5th Nov '24 - 9th Nov '24",
      "investment": "₹ 1,80,000"
    },
    {
        "program_name": "HDB 1",
        "timeline": "8 Days",
        "schedule": "29th Sept '24 - 6th Oct '24",
        "investment": "₹ 2,34,000"
      },
      {
        "program_name": "HDB 2",
        "timeline": "10 Days",
        "schedule": "10th Oct '24 - 19th Oct '24",
        "investment": "₹ 2,50,000"
      },
      {
        "program_name": "HDB 3",
        "timeline": "7 Days",
        "schedule": "20th Oct '24 - 26th Oct '24",
        "investment": "₹ 2,10,000"
      },
      {
        "program_name": "HDB 4",
        "timeline": "9 Days",
        "schedule": "27th Oct '24 - 4th Nov '24",
        "investment": "₹ 2,45,000"
      },
      {
        "program_name": "HDB 5",
        "timeline": "5 Days",
        "schedule": "5th Nov '24 - 9th Nov '24",
        "investment": "₹ 1,80,000"
      },
      {
        "program_name": "HDB 1",
        "timeline": "8 Days",
        "schedule": "29th Sept '24 - 6th Oct '24",
        "investment": "₹ 2,34,000"
      },
      {
        "program_name": "HDB 2",
        "timeline": "10 Days",
        "schedule": "10th Oct '24 - 19th Oct '24",
        "investment": "₹ 2,50,000"
      },
      {
        "program_name": "HDB 3",
        "timeline": "7 Days",
        "schedule": "20th Oct '24 - 26th Oct '24",
        "investment": "₹ 2,10,000"
      },
      {
        "program_name": "HDB 4",
        "timeline": "9 Days",
        "schedule": "27th Oct '24 - 4th Nov '24",
        "investment": "₹ 2,45,000"
      },
      {
        "program_name": "HDB 5",
        "timeline": "5 Days",
        "schedule": "5th Nov '24 - 9th Nov '24",
        "investment": "₹ 1,80,000"
      }
  ]
  export const mahatria = "Mahatria's"

