import mahatriaIcon from "../../../assets/images/mahatria-icon.svg";
import mahatriaLogo from "../../../assets/images/mahatria-logo.svg";
import profileIcon from "../../../assets/images/defulat-profile.svg";
import styles from "../AppHeader/index.module.scss";
// import dropdown from "../../../assets/images/dropdown.svg"
import { Outlet, useNavigate } from "react-router-dom";
import { STRINGS } from "../../constants/stringConstants";
import { getItemInLocalStorage } from "../../../services/localStorage";
import { Tooltip } from '@mui/material';
interface IProps {
  variant: string;
}

const AppHeader = (props: IProps) => {
  const navigate = useNavigate();
  // const location = window.location.pathname;
  const signOut = () => {
    navigate("/");
    localStorage.clear();
  };
  // const adminName = () => {
  //   return (
  //     location.includes('/room-allocation') 
  //   );
  // };
  return (
    <>
      <div className={styles.header}>
        <div className={styles.appIconSection}>
          <img src={mahatriaIcon} alt="mahatria" />
          <div className={styles.appTitle}>{STRINGS.APP_TITLE}</div>
        </div>
        <img src={mahatriaLogo} alt="logo" />
        
        <div className={styles.profileSection}>
            {/* {adminName() && window?.innerWidth >= 1024 && ( */}
            <>
                  <div className={styles.containerNames}>
                    {getItemInLocalStorage('userName')?.length > 15 ? (
                      <Tooltip
                        title={getItemInLocalStorage('userName') || ''}
                        arrow
                      >
                        <p className={styles.userNameEcllipse}>
                          {getItemInLocalStorage('userName') || ''}
                        </p>
                      </Tooltip>
                    ) : (
                      <p className={styles.userName}>
                        {getItemInLocalStorage('userName') || ''}
                      </p>
                    )}
                  </div>
                  {/* <div className={styles.profile}>
                    <img src={profile} alt="profile" loading="lazy" />
                  </div> */}
                  <span className={styles.verticalLine}></span>
                </>
              {/* )}  */}
          {props.variant == "primary" ? (
            <>
              <img
                src={profileIcon}
                alt="profile"
                className={styles.profileIcon}
              />
              <button
                className={styles.profileDropdownButton}
                onClick={signOut}
              >
                {STRINGS.LOGOUT}
              </button>
            </>
          ) : null}
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default AppHeader;
