export const loader = {
  v: '4.8.0',
  meta: { g: 'LottieFiles AE 3.5.7', a: '', k: '', d: '', tc: '' },
  fr: 30,
  ip: 0,
  op: 136,
  w: 3600,
  h: 3600,
  nm: 'Loader',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Shape Layer 18',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 123.188,
              s: [100],
            },
            { t: 135, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 123.379,
              s: [1280.25, 1799.922, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 127.46875, s: [1696.25, 1799.922, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [-759.75, -447.25, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1040, -446.903],
                    [128, -446.903],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.258823529412, 0.647058823529, 0.960784313725, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 30, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 112.473,
                s: [0],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 115.199,
                s: [0],
              },
              { t: 123.37890625, s: [76] },
            ],
            ix: 1,
          },
          e: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 112.473,
                s: [0],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 115.199,
                s: [100],
              },
              { t: 123.37890625, s: [100] },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 2,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false,
        },
      ],
      ip: -2,
      op: 206,
      st: 165,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Shape Layer 17',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 118.606,
              s: [100],
            },
            { t: 126.7861328125, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 111.79,
              s: [1280.25, 1799.922, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 115.880859375, s: [1696.25, 1799.922, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [-759.75, -447.25, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1040, -446.903],
                    [128, -446.903],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.172549019608, 0.392156862745, 0.674509803922, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 30, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 100.885,
                s: [0],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 103.61,
                s: [0],
              },
              { t: 111.7900390625, s: [76] },
            ],
            ix: 1,
          },
          e: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 100.885,
                s: [0],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 103.61,
                s: [100],
              },
              { t: 111.7900390625, s: [100] },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 2,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false,
        },
      ],
      ip: -5,
      op: 189,
      st: 148,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Shape Layer 16',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 107.02,
              s: [100],
            },
            { t: 115.19921875, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 100.203,
              s: [1280.25, 1799.922, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 104.2919921875, s: [1696.25, 1799.922, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [-759.75, -447.25, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1040, -446.903],
                    [128, -446.903],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.258823529412, 0.647058823529, 0.960784313725, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 30, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 89.296,
                s: [0],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 92.023,
                s: [0],
              },
              { t: 100.203125, s: [76] },
            ],
            ix: 1,
          },
          e: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 89.296,
                s: [0],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 92.023,
                s: [100],
              },
              { t: 100.203125, s: [100] },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 2,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false,
        },
      ],
      ip: -15,
      op: 172,
      st: 131,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'Shape Layer 15',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 95.431,
              s: [100],
            },
            { t: 103.6103515625, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 88.614,
              s: [1280.25, 1799.922, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 92.705078125, s: [1696.25, 1799.922, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [-759.75, -447.25, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1040, -446.903],
                    [128, -446.903],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.172549019608, 0.392156862745, 0.674509803922, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 30, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 77.709,
                s: [0],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 80.435,
                s: [0],
              },
              { t: 88.6142578125, s: [76] },
            ],
            ix: 1,
          },
          e: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 77.709,
                s: [0],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 80.435,
                s: [100],
              },
              { t: 88.6142578125, s: [100] },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 2,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false,
        },
      ],
      ip: -3,
      op: 155,
      st: 114,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'Shape Layer 14',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 83.843,
              s: [100],
            },
            { t: 92.0234375, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 77.027,
              s: [1280.25, 1799.922, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 81.1162109375, s: [1696.25, 1799.922, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [-759.75, -447.25, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1040, -446.903],
                    [128, -446.903],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.258823529412, 0.647058823529, 0.960784313725, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 30, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 66.119,
                s: [0],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 68.847,
                s: [0],
              },
              { t: 77.02734375, s: [76] },
            ],
            ix: 1,
          },
          e: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 66.119,
                s: [0],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 68.847,
                s: [100],
              },
              { t: 77.02734375, s: [100] },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 2,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false,
        },
      ],
      ip: -20,
      op: 138,
      st: 97,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      nm: 'Shape Layer 13',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 72.255,
              s: [100],
            },
            { t: 80.4345703125, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 65.438,
              s: [1280.25, 1799.922, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 69.529296875, s: [1696.25, 1799.922, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [-759.75, -447.25, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1040, -446.903],
                    [128, -446.903],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.172549019608, 0.392156862745, 0.674509803922, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 30, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 54.532,
                s: [0],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 57.259,
                s: [0],
              },
              { t: 65.4384765625, s: [76] },
            ],
            ix: 1,
          },
          e: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 54.532,
                s: [0],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 57.259,
                s: [100],
              },
              { t: 65.4384765625, s: [100] },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 2,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false,
        },
      ],
      ip: -37,
      op: 121,
      st: 80,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: 'Shape Layer 12',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 60.667,
              s: [100],
            },
            { t: 68.8466796875, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 53.851,
              s: [1280.25, 1799.922, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 57.9404296875, s: [1696.25, 1799.922, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [-759.75, -447.25, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1040, -446.903],
                    [128, -446.903],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.258823529412, 0.647058823529, 0.960784313725, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 30, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 42.943,
                s: [0],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 45.671,
                s: [0],
              },
              { t: 53.8505859375, s: [76] },
            ],
            ix: 1,
          },
          e: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 42.943,
                s: [0],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 45.671,
                s: [100],
              },
              { t: 53.8505859375, s: [100] },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 2,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false,
        },
      ],
      ip: -54,
      op: 104,
      st: 63,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 4,
      nm: 'Shape Layer 11',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 49.079,
              s: [100],
            },
            { t: 57.2587890625, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 42.262,
              s: [1280.25, 1799.922, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 46.3515625, s: [1696.25, 1799.922, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [-759.75, -447.25, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1040, -446.903],
                    [128, -446.903],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.172549019608, 0.392156862745, 0.674509803922, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 30, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 31.355,
                s: [0],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 34.082,
                s: [0],
              },
              { t: 42.26171875, s: [76] },
            ],
            ix: 1,
          },
          e: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 31.355,
                s: [0],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 34.082,
                s: [100],
              },
              { t: 42.26171875, s: [100] },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 2,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false,
        },
      ],
      ip: -71,
      op: 87,
      st: 46,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 12,
      ty: 4,
      nm: 'Shape Layer 9',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 37.491,
              s: [100],
            },
            { t: 45.6708984375, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 30.674,
              s: [1280.25, 1799.922, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 34.763671875, s: [1696.25, 1799.922, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [-759.75, -447.25, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1040, -446.903],
                    [128, -446.903],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.258823529412, 0.647058823529, 0.960784313725, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 30, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 19.768,
                s: [0],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 22.494,
                s: [0],
              },
              { t: 30.673828125, s: [76] },
            ],
            ix: 1,
          },
          e: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 19.768,
                s: [0],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 22.494,
                s: [100],
              },
              { t: 30.673828125, s: [100] },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 2,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false,
        },
      ],
      ip: -88,
      op: 70,
      st: 29,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 13,
      ty: 4,
      nm: 'Shape Layer 8',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 25.903,
              s: [100],
            },
            { t: 34.08203125, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 19.086,
              s: [1280.25, 1799.922, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 23.17578125, s: [1696.25, 1799.922, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [-759.75, -447.25, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1040, -446.903],
                    [128, -446.903],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.172549019608, 0.392156862745, 0.674509803922, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 30, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 8.18,
                s: [0],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 10.906,
                s: [0],
              },
              { t: 19.0859375, s: [76] },
            ],
            ix: 1,
          },
          e: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 8.18,
                s: [0],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 10.906,
                s: [100],
              },
              { t: 19.0859375, s: [100] },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 2,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false,
        },
      ],
      ip: -105,
      op: 54,
      st: 12,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 14,
      ty: 4,
      nm: 'Shape Layer 3',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 14.314,
              s: [100],
            },
            { t: 22.494140625, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 7.498,
              s: [1280.25, 1799.922, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 11.587890625, s: [1696.25, 1799.922, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [-759.75, -447.25, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-1040, -446.903],
                    [128, -446.903],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.258823529412, 0.647058823529, 0.960784313725, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 30, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Shape 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'tm',
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 0,
                s: [0],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 2.727,
                s: [0],
              },
              { t: 10.90625, s: [76] },
            ],
            ix: 1,
          },
          e: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 0,
                s: [0],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 2.727,
                s: [100],
              },
              { t: 10.90625, s: [100] },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 2,
          ix: 2,
          nm: 'Trim Paths 1',
          mn: 'ADBE Vector Filter - Trim',
          hd: false,
        },
      ],
      ip: -117,
      op: 37,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
